<template>
  <div class="pa-10">
    <title-page icon="ico-billingRequest">
      {{ $tc('billing-request.billing-request', 2) }}
    </title-page>




    <v-card>
      <v-card-text>
        <v-row dense>
          <v-col>
            <date-picker
                label="Date de création"
                v-model="rangeDate"
                clearable
                hide-details
                range
            />
          </v-col>
          <v-col>
            <date-picker
                label="Date de la période"
                v-model="rangePeriodDate"
                clearable
                hide-details
                range
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <organization-select
                :label="$t('organization.organization')"
                clearable
                v-model="filters.organizationId"
            />
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>




    <v-divider class="my-6"/>

    <v-card class="my-3" color="primary">
      <v-card-text class=" white--text" style="font-size: 20px;">
        <v-row>
          <v-col>
            <v-row>
              <v-col>Nb de demande de facturation</v-col>
              <v-col cols="auto" class="text-right"><b>{{ stats.total }}</b></v-col>
            </v-row>
          </v-col>
          <v-divider inset vertical />
          <v-col>
            <v-row>
              <v-col>Total HT</v-col>
              <v-col cols="auto" class="text-right"><b>{{ numberFormat(stats.netTotal) }} €</b></v-col>
            </v-row>
          </v-col>
          <v-divider inset vertical />
          <v-col>
            <v-row>
              <v-col>Total TTC</v-col>
              <v-col cols="auto" class="text-right"><b>{{ numberFormat(stats.finalTotal) }} €</b></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-divider class="my-6"/>

    <v-row>
      <v-col >
        <v-data-table
          :headers="headers"
          :items="billingRequests"
          :options.sync="options"
          :loading="loading"
          :server-items-length="total"
        >
          <template #item.createdAt="{item}">
            {{ item.createdAt | formatDate($t('format_date')) }}
          </template>
          <template #item.startDate="{item}">
            {{ formatUtcDate(item.startDate, $t('format_date')) }} - {{ formatUtcDate(item.endDate, $t('format_date')) }}
          </template>

          <template #item.netTotal="{item}">
            {{ item.netTotal ? numberFormat(item.netTotal) + ' €' : '-' }}
          </template>

          <template #item.status="{item}">
            {{ $t('invoice.statues.' + item.status) }}
          </template>


          <template #item.options="{item}">
            <v-btn icon small @click="openBillingRequest(item)">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>


    <billingRequest-show-modal ref="billingRequestModal" />

  </div>
</template>

<script>
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";
import BillingRequestShowModal from "@modals/BillingRequestShowModal.vue";
import BillingRequestRepository from "@repository/BillingRequestRepository";

export default {
  components: {
    OrganizationSelect,
    BillingRequestShowModal,
  },
  data() {
    return {
      loading: false,
      allOperators: [],
      rangeDate: [],
      rangePeriodDate: [],
      stats: {
        total: 0,
        netTotal: 0,
        finalTotal: 0,
      },
      filters: {
        organizationId: null,
      },
      total: 0,
      billingRequests: [],
      options: null,
    }
  },

  mounted() {
    this.loadOperators()
  },

  watch: {
    filters: {
      handler() {
        if(this.options.page > 1) {
          this.options.page = 1
        } else {
          this.loadData()
        }
      },
      deep: true
    },

    rangePeriodDate() {
      this.loadData()
    },

    rangeDate() {
      this.loadData()
    },

    options() {
      this.loadData()
    },

    recipientType() {
      this.showLoading(true)
      this.filters = {
        organizationId: null,
        contentType: null,
        customerId: null,
        operatorId: null,
        partnerOperators: null,
      }
    },



  },

  computed: {

    headers() {
      return [
        {text: this.$t('created-at'), value: 'createdAt'},
        {text: this.$t('billing-request.reference'), value: 'billingRequestReference'},
        {text: this.$t('period'), value: 'startDate'},
        {text: this.$t('name'), value: 'issuerName'},
        {text: this.$t('price-ht'), value: 'netTotal'},
        {text: this.$t('status'), value: 'status'},
        {value: 'options'},
      ]
    },


    operators() {
      return this.allOperators.filter(partner => partner.ocpi)
    },

    partners() {
      return this.allOperators.filter(partner => !partner.ocpi)
    },

  },

  methods: {

    loadData() {

      this.loading = true
      let data = {
        ...this.filters,
      }


      if(this.rangeDate?.length > 0) {
        data.startDate = this.rangeDate[0]
        data.endDate = this.rangeDate[1]
      }

      if(this.rangePeriodDate?.length > 0) {
        data.startPeriodDate = this.rangePeriodDate[0]
        data.endPeriodDate = this.rangePeriodDate[1]
      }


      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      if (this.options.sortBy.length > 0) {
        data.sortBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc'
      }


      BillingRequestRepository.search(data).then(response => {
        this.stats = response.stats
        this.total = response.total
        this.billingRequests = response.items
        this.loading = false
        this.showLoading(false)
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        this.loading = false
        this.showLoading(false)
      })
    },

    openBillingRequest(billingRequest) {
      console.log(billingRequest)
      this.$refs.billingRequestModal.open(billingRequest.id)
    },

    loadOperators() {
      BillingRequestRepository.search().then(operators => {
        this.allOperators = operators
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
      })
    },
  },

}
</script>

<style lang="scss">


</style>
