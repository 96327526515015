import Vue from 'vue'

class BillingRequestRepository {

  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/search',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/billing-requests/get/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


}

export default new BillingRequestRepository()
