import Vue from 'vue'

class InvoiceRepository {

  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/invoices/search',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/invoices/get/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  pdf(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: '/api/invoices/pdf/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  inlinePdf(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/invoices/pdf/' + id,
        method: 'get',
        responseType: 'arraybuffer'
      })
          .then(response => {
            let blob = new Blob([response.data], { type: response.headers['content-type'] } );
            let url = window.URL.createObjectURL(blob);
            resolve(url)
          }).catch(err => {
        reject(err)
      })
    })
  }


}

export default new InvoiceRepository()
